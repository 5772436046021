<div class="container page-content">
  <div class="page-header">
    <h1>{{ "sponsoredFamiliesOffer" | i18n }}</h1>
  </div>
  <div *ngIf="loading" class="mt-5 d-flex justify-content-center">
    <i
      class="bwi bwi-spinner bwi-spin bwi-2x text-muted"
      title="{{ 'loading' | i18n }}"
      aria-hidden="true"
    ></i>
    <span class="sr-only">{{ "loading" | i18n }}</span>
  </div>
  <div *ngIf="!loading && badToken" class="mt-5 d-flex justify-content-center">
    <span>{{ "badToken" | i18n }}</span>
  </div>
  <form
    #form
    (ngSubmit)="submit()"
    [appApiAction]="formPromise"
    ngNativeValidate
    *ngIf="!loading && !badToken"
  >
    <p>
      <span>{{ "acceptSensInfoFamiliesHelp" | i18n }}</span>
    </p>
    <div class="form-group col-6">
      <label for="availableSponsorshipOrg">{{ "sponsoredFamiliesSelectOffer" | i18n }}</label>
      <select
        id="availableSponsorshipOrg"
        name="Available Sponsorship Organization"
        [(ngModel)]="selectedFamilyOrganizationId"
        class="form-control"
        required
      >
        <option value="" disabled>-- {{ "select" | i18n }} --</option>
        <option value="createNew">{{ "newFamiliesOrganization" | i18n }}</option>
        <option *ngFor="let o of existingFamilyOrganizations$ | async" [ngValue]="o.id">
          {{ o.name }}
        </option>
      </select>
    </div>
    <div *ngIf="showNewOrganization" class="col-12">
      <app-organization-plans></app-organization-plans>
    </div>
    <div class="form-group col-6" *ngIf="!showNewOrganization">
      <button class="btn btn-primary mt-2 btn-submit" [disabled]="form.loading" type="submit">
        <i class="bwi bwi-spinner bwi-spin" title="{{ 'loading' | i18n }}" aria-hidden="true"></i>
        <span>{{ "acceptOffer" | i18n }}</span>
      </button>
    </div>
  </form>
</div>
